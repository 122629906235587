<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Answer each of the questions listed below. Select all options that apply.</p>

      <p class="mb-2">
        What quantum numbers are required in order to determine the energy of an electron in a
        many-electron atom?
      </p>

      <v-row class="pl-14 mb-2">
        <v-checkbox
          v-for="option in options1"
          :key="'pt-1-' + option.value"
          v-model="inputs.input1"
          class="pr-12"
          :value="option.value"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-2">
        What quantum number is the most important to determine the size of an atomic orbital?
      </p>

      <v-row class="pl-14 mb-2">
        <v-checkbox
          v-for="option in options1"
          :key="'pt-2-' + option.value"
          v-model="inputs.input2"
          :value="option.value"
          class="pr-12"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-2">What quantum number describes the orientation of an atomic orbital?</p>

      <v-row class="pl-14 mb-2">
        <v-checkbox
          v-for="option in options1"
          :key="'pt-3-' + option.value"
          v-model="inputs.input3"
          :value="option.value"
          class="pr-12"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>

      <p class="mb-2">What quantum number describes the general shape of an atomic orbital?</p>

      <v-row class="pl-14 mb-0">
        <v-checkbox
          v-for="option in options1"
          :key="'pt-4-' + option.value"
          v-model="inputs.input4"
          :value="option.value"
          class="pr-12"
        >
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question18',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: [],
        input2: [],
        input3: [],
        input4: [],
      },
      options1: [
        {text: '$n$', value: 'n'},
        {text: '$\\ell$', value: 'l'},
        {text: '$m_\\ell$', value: 'ml'},
        {text: '$m_s$', value: 'ms'},
      ],
    };
  },
};
</script>
<style scoped>
.v-input--checkbox {
  display: inline-block !important;
}
</style>
